import UqDatingIcon from '@uq-assets/nav-icons/uq-score/dating.svg';
import UqELearningIcon from '@uq-assets/nav-icons/uq-score/e-learning.svg';
import UqFinanceIcon from '@uq-assets/nav-icons/uq-score/finance.svg';
import UqHealthIcon from '@uq-assets/nav-icons/uq-score/fitness.svg';
import UqFoodIcon from '@uq-assets/nav-icons/uq-score/food.svg';
import UqGamingIcon from '@uq-assets/nav-icons/uq-score/gaming.svg';
import UqMusicIcon from '@uq-assets/nav-icons/uq-score/music.svg';
import UqShoppingIcon from '@uq-assets/nav-icons/uq-score/shopping.svg';
import UqTopAppsIcon from '@uq-assets/nav-icons/uq-score/top-apps.svg';
import UqTravelIcon from '@uq-assets/nav-icons/uq-score/travel.svg';
import UqVideoIcon from '@uq-assets/nav-icons/uq-score/video.svg';

export const Content = {
  categories: {
    'top-apps': {
      description: `Your ability to captivate users with high\u2011quality experiences can be the difference
      between renewing a subscription or sending a “we miss you” email. Use the unitQ Score to ensure your
      product and services continue to exceed user expectations.`,
      icon: UqTopAppsIcon,
      id: 'topapps',
      title: 'Measure the user experience in real time',
    },
    'dating': {
      description: 'Online dating apps rely on fast swipes and engaging interactions to help every user make a personal love connection. ' +
        'Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.',
      icon: UqDatingIcon,
      id: 'dating',
      title: 'Measure the user experience in real time',
    },
    'e-learning': {
      description: `E\u2011learning platforms are a popular destination for
      people looking to earn industry certifications, learn new languages and more.
      Use the unitQ Score to ensure you are providing exceptional learning experiences to drive growth and beat the competition.`,
      icon: UqELearningIcon,
      id: 'elearning',
      title: 'Measure the learner experience in real time',
    },
    'finance': {
      description: `From transferring money abroad to investing in the stock market,
      finance has moved out of the bank and into people’s hands.
      Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.`,
      icon: UqFinanceIcon,
      id: 'finance',
      title: 'Measure the user experience in real time',
    },
    'food': {
      description: `From placing an order to redeeming rewards,
      it has never being easier to engage with your favorite restaurant or food service.
      Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.`,
      icon: UqFoodIcon,
      id: 'food',
      title: 'Measure the user experience in real time',
    },
    'gaming': {
      description: `With over 3 billion active players across the globe and an infinite supply of games,
      it can be hard to stand out from the crowd.
      Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.`,
      icon: UqGamingIcon,
      id: 'gaming',
      title: 'Measure the gamer experience in real time',
    },
    'health-and-fitness': {
      description: `A new generation of fitness and wellness platforms are
      helping people stay healthy and tracking towards their lifestyle goals.
      Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.`,
      icon: UqHealthIcon,
      id: 'healthandfitness',
      title: 'Measure the user experience in real time',
    },
    'music': {
      description: 'Every music fan craves a personalized experience, while each artist wants a engaging forum to promote their tunes. ' +
        'Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.',
      icon: UqMusicIcon,
      id: 'music',
      title: 'Measure the user experience in real time',
    },
    'shopping': {
      description: 'Shopping platforms have made it convenient for consumers to purchase good and services from anywhere. ' +
        'Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.',
      icon: UqShoppingIcon,
      id: 'shopping',
      title: 'Measure the shopper experience in real time',
    },
    'travel': {
      description: 'Travel platforms allow users to book transportation, lodging, and experiences from anywhere with just a few clicks. ' +
        'Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.',
      icon: UqTravelIcon,
      id: 'travel',
      title: 'Measure the user experience in real time',
    },
    'video': {
      description: `From creators to viewers, video continues to be a popular type of media for expression,
      communication and entertainment.
      Use the unitQ Score to ensure you are providing an exceptional user experience to drive growth and beat the competition.`,
      icon: UqVideoIcon,
      id: 'video',
      title: 'Measure the user experience in real time',
    },
  },
  seo: {
    appendHostUrl: true,
    description: `Users share valuable feedback with you every day on social media, help desk tickets, review sites, surveys, and more.
    unitQ centralizes feedback from all sources and automatically groups it into thousands of granular
    categories to help organizations discover what matters most to users —all in real time.
    The unitQ Score represents the percentage of your users reporting a frictionless experience.
    Our advanced AI analyzes all feedback data and pinpoints quality issues impacting the user experience.`,
    src: '/images/v2/seo/home.jpg',
    title: 'unitQ Score',
  },
};

export const UqScoreCategoryLabels = new Map([
  ['top-apps', 'Top Apps'],
  ['dating', 'Dating'],
  ['e-learning', 'E-Learning'],
  ['finance', 'Finance'],
  ['food', 'Food'],
  ['gaming', 'Gaming'],
  ['health-and-fitness', 'Health & Fitness'],
  ['music', 'Music'],
  ['shopping', 'Shopping'],
  ['travel', 'Travel'],
  ['video', 'Video'],
]);
