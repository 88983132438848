import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { figureRowShape } from '../../models';
import { UqPage, UqLayoutV2, UqHeroV2, UqAppBarV2, UqNavbarV2, UqFooterV2, UqContainer, UqScoreRankings, UqFigure, UqRequestDemoBanner, UqFilterBar } from '../../components/v2';
import { Content, UqScoreCategoryLabels } from '../../content/v2/uq-score';
import { useQueryParams } from '../../hooks/v2';

import * as styles from './unitqscore.module.scss';

const CATEGORY_PARAM = 'category';

const scoreCategoryOptions = [
  { key: 'top-apps', label: 'Top Apps' },
  { key: 'dating', label: 'Dating' },
  { key: 'e-learning', label: 'E-Learning' },
  { key: 'finance', label: 'Finance' },
  { key: 'food', label: 'Food' },
  { key: 'gaming', label: 'Gaming' },
  { key: 'health-and-fitness', label: 'Health & Fitness' },
  { key: 'music', label: 'Music' },
  { key: 'shopping', label: 'Shopping' },
  { key: 'travel', label: 'Travel' },
  { key: 'video', label: 'Video' },
];

UqScorePage.propTypes = {
  data: PropTypes.shape({
    figure: figureRowShape,
  }),
  pageContext: PropTypes.shape({
    categories: PropTypes.shape(),
  }),
};

export default function UqScorePage(props) {
  const { figure } = props.data;
  const { categories } = props.pageContext;

  const currentMonth = useMemo(() => new Date().toLocaleString('en-US', { month: 'long' }), []);

  const { params, updateParameter } = useQueryParams([CATEGORY_PARAM]);

  const selectedCategory = params[CATEGORY_PARAM] || 'top-apps';

  const categoryContent = Content.categories[selectedCategory] || null;
  const categoryLabel = UqScoreCategoryLabels.get(selectedCategory) || null;
  const CategoryIcon = categoryContent?.icon;

  const apps = categories[selectedCategory]?.apps || [];

  const iconClasses = classNames(styles.icon, styles[categoryContent?.id]);
  const descClasses = classNames('body-2', styles.rankingsDesc);
  const subtitleClasses = classNames('body-1', styles.subtitle);

  if (!selectedCategory) {
    return null;
  }

  return (
    <UqPage config={{ seo: Content.seo }}>
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqContainer>
          <UqHeroV2>
            <h1 className={styles.header}>
              {'Top unitQ Scores \n by Vertical'}
            </h1>
            <section className={styles.filterContainer}>
              <section className={styles.filterBar}>
                <UqFilterBar
                  options={scoreCategoryOptions}
                  onOptionSelect={(key) => updateParameter(CATEGORY_PARAM, key)}
                  value={selectedCategory}
                >
                </UqFilterBar>
              </section>
            </section>
          </UqHeroV2>
        </UqContainer>
        <UqContainer>
          <section className={styles.rankingsHeader}>
            <h3>
              <span className={styles.iconWrapper}>
                <CategoryIcon className={iconClasses} />
              </span>
              {categoryLabel}
            </h3>
            <div className={descClasses}>
              <div><strong>{categoryContent.title}</strong></div>
              <p>{categoryContent.description}</p>
            </div>
          </section>
          <p className={subtitleClasses}>
            unitQ Score 30 Day Average: {currentMonth}
          </p>
          <UqScoreRankings items={apps} />
        </UqContainer>
        <UqContainer>
          <UqFigure reverse={!figure.textAlign}>
            <UqFigure.Content>
              <UqFigure.Title>{figure.title}</UqFigure.Title>
              <span>{figure.description.description}</span>

              <UqFigure.Actions actions={figure.actions} />
            </UqFigure.Content>

            <UqFigure.Image src={figure.image.url} />
          </UqFigure>
          <UqRequestDemoBanner />
        </UqContainer>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}

export const pageQuery = graphql`
  query {
    figure: contentfulFigureRow(pageId: {eq: "uq-score"}) {
      actions {
        href
        label
        metadata {
          variant
        }
        type
      }
      description {
        description
      }
      image {
        url
      }
      title
    }
  }
`;
